import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import { makeStyles } from '@material-ui/core/styles'

import Copy from '@objects/copy'
import Headline from '@objects/headline'
import Icon from '@objects/icon'
import Carousel from '@objects/carousel'

import Img from 'gatsby-image'
import clsx from 'clsx'
import { useTheme } from '@hooks'

const Swiper = styled(Carousel)`
  .swiper-slide {
    transition-property: none;
    &-prev,
    &-duplicate-prev,
    &.swiper-slide-prev {
      transform: none;
    }
    &.swiper-slide-next {
      transform: none;
    }
  }
  .button {
    display: none;
  }
`

const Lightbox = styled.div`
  ${tw`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25`}
`

const LightboxContent = styled.div`
  ${tw`relative bg-white py-8 px-6 w-full md:w-9/12`}
`

const useStyles = makeStyles((theme) => ({
  renderContainer: {
    width: '520px',

    [theme.breakpoints.down('sm')]: {
      width: '310px',
    },
  },
  image: {
    cursor: 'pointer',
    height: '340px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '260px',
    },
  },
  gradientBase: {
    position: 'absolute',
    height: '80%',
    width: '100%',
    zIndex: 50,
    pointerEvents: 'none',
  },
  gradientLeft: {
    right: '0',
    background:
      'linear-gradient(to left, rgba(255, 255, 255, 0) 90%, rgb(255, 255, 255) 100%)',
  },
  gradientRight: {
    right: '0',
    background:
      'linear-gradient(to right, rgba(255, 255, 255, 0) 90%, rgb(255, 255, 255) 100%)',
  },
  buttonBase: {
    cursor: 'pointer',
    display: 'flex',
    top: '30%',
    position: 'absolute',
    zIndex: '60',
    borderRadius: '50%',
    backgroundColor: 'rgb(0,56,94)',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '56px',
    height: '56px',
    transition: 'background-color 0.1s ease-in-out',

    '& svg': {
      width: '45px',
      height: '45px',
    },

    '&:hover': {
      backgroundColor: 'rgb(208,218,221)',
      '& svg': {
        color: 'black',
      },
    },

    [theme.breakpoints.down('sm')]: {
      top: '25%',
    },
  },
  buttonRight: {
    right: '8px',
  },
  buttonLeft: {
    left: '8px',
  },
}))

function ImageCarousel({ items, headline }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [showPrevButton, setShowPrevButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(true)
  const classes = useStyles()
  const { screens } = useTheme()
  const swiperRef = useRef(null)

  const handleImageClick = (item) => {
    setSelectedImage(item)
    setIsOverlayVisible(true)
  }

  const handleCloseLightbox = () => {
    setIsOverlayVisible(false)
    setSelectedImage(null)
  }

  const showButtonCheck = () => {
    setShowPrevButton(swiperRef.current.firstChild.swiper.activeIndex !== 0)
    setShowNextButton(!swiperRef.current.firstChild.swiper.isEnd)
  }

  const handleButtonPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.firstChild.swiper.slidePrev()
      showButtonCheck()
    }
  }
  const handleButtonNext = () => {
    if (swiperRef.current) {
      swiperRef.current.firstChild.swiper.slideNext()
      showButtonCheck()
    }
  }

  useEffect(() => {
    setIsMobile(window.innerWidth < parseInt(screens.md))
  }, [screens])

  return (
    <>
      {headline && (
        <Headline className="text-center" ariaLabel={headline}>
          {headline}
        </Headline>
      )}
      <div className="relative md:py-1">
        {showPrevButton && !isMobile && (
          <div
            className={clsx(classes.buttonBase, classes.buttonLeft)}
            onClick={handleButtonPrev}
          >
            <Icon name="Prev" />
          </div>
        )}
        {showNextButton && !isMobile && (
          <div
            className={clsx(classes.buttonBase, classes.buttonRight)}
            onClick={handleButtonNext}
          >
            <Icon name="Next" />
          </div>
        )}
        <div className={clsx(classes.gradientBase, classes.gradientLeft)} />
        <div className={clsx(classes.gradientBase, classes.gradientRight)} />
        {items && items.length > 0 && (
          <div className="relative" ref={swiperRef}>
            <Swiper slidesPerView={'auto'} centeredSlides={true} loop={false}>
              {items.map((item, i) => (
                <div
                  key={`${item.created}${i}`}
                  className={clsx(
                    classes.renderContainer,
                    'my-9 flex align-center'
                  )}
                >
                  <div
                    className="mx-auto my-0 flex flex-col w-full h-full"
                    onClick={() => handleImageClick(item)}
                  >
                    {item.image && (
                      <>
                        <Img
                          alt={item.image.description}
                          fluid={{
                            ...item.image.fluid,
                            media: `(min-width: 0px)`,
                          }}
                          fadeIn={false}
                          backgroundColor={true}
                          loading={'eager'}
                          className={classes.image}
                        />
                        <Copy className="sm:text-lg text-base text-black-650 pt-3 leading-4">
                          {item.image.description}
                        </Copy>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
        )}
        {isOverlayVisible && selectedImage && (
          <Lightbox>
            <LightboxContent style={{ borderRadius: '5px' }}>
              <div
                onClick={handleCloseLightbox}
                className="absolute top-0 right-2 m-2 text-black cursor-pointer"
              >
                <Icon name="Close" />
              </div>
              <Img
                alt={selectedImage.image.description}
                fluid={{
                  ...selectedImage.image.fluid,
                  media: `(min-width: 0px)`,
                }}
                fadeIn={false}
                backgroundColor={true}
                loading={'eager'}
              />
              <Copy className="sm:text-lg text-base text-black-650 pt-3 leading-4">
                {selectedImage.image.description}
              </Copy>
            </LightboxContent>
          </Lightbox>
        )}
      </div>
    </>
  )
}

ImageCarousel.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
    })
  ).isRequired,
}

export default ImageCarousel
